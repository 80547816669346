<template>
  <div>

    <div v-if="!show404 && !show403">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

      <!-- Basic info tab -->
      <div class="card" id="update_basic_info">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <h4 qid="tp-view-page-title" class="mb-2">
                <Avatar :object="thirdParty" size="xs" class="mr-2"></Avatar>
                {{ thirdParty.name }}
              </h4>
              <div class="mb-2">
                <span v-if="getGuide('update_basic_info')">
                  <GuideButton :guide="getGuide('update_basic_info')"></GuideButton>
                </span>
              </div>
            </div>

            <div class="col-md-6 text-right" qid="view-tp-modified">
              <div class="mb-4">
                <span class="h6 ml-3">
                  <span v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                        class="btn btn-edit"
                        qid="edit-tp-button"
                        @click="showEditTPModal(thirdParty)"
                  >
                    <font-awesome-icon icon="pen"/>
                  </span>
                  <span v-if="isGranted([permissions.THIRD_PARTY_DELETE, permissions.THIRD_PARTY_DELETE_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                        class="btn btn-delete"
                        qid="delete-tp-button"
                        @click="showConfirmDeleteModal(thirdParty)"
                  >
                    <font-awesome-icon icon="trash-alt"/>
                  </span>
                </span>
              </div>
              <div>
                <span>{{ $t('table.last_updated') }}</span>
                <span class="ml-2 text-muted small" qid="view-tp-modified-date">
                {{ thirdParty.updated_at | formatDate }}
              </span>
                <Avatar v-if="thirdParty.updated_by"
                        :object="thirdParty.updated_by"
                        size="xs"
                        class="ml-3 mr-2"
                ></Avatar>
                <span v-if="thirdParty.updated_by" qid="view-tp-modified-by">
                {{ thirdParty.updated_by.name }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-tabs class="mt-3" v-model="tabIndex">

        <!-- First tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.basic_info')" active @click="resetHistoryTab">
          <br>
          <div class="card">
            <div class="card-body">
              <div class="content-view-body">
                <div>
                  <h5 class="h5">
                    <span class="float-right btn-sm btn-edit"
                          @click="showDescriptionInputFunction"
                          qid="edit-process-description"
                          v-if="!showDescriptionInput && isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                    >
                      <font-awesome-icon icon="pen"/>
                    </span>
                    <span class="float-right" v-if="showDescriptionInput">
                      <div class="text-right">
                        <b-button class="btn-sm inline-edit-button"
                                  variant="secondary"
                                  @click="cancelDescriptionForm"
                                  qid="process-description-cancel-button"
                        >
                          {{ $t('processes.legal_basis.create.cancel') }}
                        </b-button>
                        <b-button class="ml-3 btn-sm inline-edit-button"
                                  variant="success"
                                  @click="formDescriptionSubmit"
                                  qid="process-description-submit-button"
                                  v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                        >
                          {{ $t('processes.legal_basis.create.submit') }}
                        </b-button>
                      </div>
                    </span>
                    {{ $t('maintenance.third_parties.create.description_label') }}
                  </h5>
                  <span v-if="getGuide('update_description')">
                    <GuideButton :guide="getGuide('update_description')"></GuideButton>
                  </span>

                  <div v-if="showDescriptionInput">
                    <b-form-textarea
                      qid="edit-p-form-description"
                      v-model="processEditDescription"
                      rows="6"
                      :placeholder="$t('maintenance.third_parties.create.description_placeholder')"
                    ></b-form-textarea>
                  </div>
                  <div v-if="!showDescriptionInput" class="section-container">
                    <div v-if="thirdParty.description && thirdParty.description.length > descriptionLength">
                      <p v-if="showFullDescription" class="text-formatted">{{ thirdParty.description }}</p>
                      <p v-if="!showFullDescription" class="text-formatted">{{ thirdParty.description | truncate(descriptionLength) }}</p>
                      <div class="text-center mt-3" @click="showFullDescription=!showFullDescription">
                        <span class="cursor-pointer" v-if="!showFullDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                    <p v-if="thirdParty.description && thirdParty.description.length <= descriptionLength"
                       class="text-formatted"
                    >{{ thirdParty.description }}</p>
                    <p v-if="!thirdParty.description" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>

          <br>

          <div class="card">
            <div class="card-header">
              <span v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
                    class="btn btn-edit cursor-pointer float-right"
                    qid="edit-p-button"
                    @click="showFormFunction"
              >
                <font-awesome-icon icon="pen"/>
              </span>
              <div class="float-left">
                <h5 class="mb-2">
                  {{ $t('maintenance.third_parties.address_label') }}
                </h5>
              </div>
            </div>


            <div class="row">
              <div class="col-sm-12">
                <div class="card-body pb-0">
                  <label for="">{{ $t('administration.clients.create.street_label') }}</label>
                  <div class="mw-500" v-if="showForm">
                    <b-form-input
                      qid="create-client-form-street"
                      type="text"
                      v-model="addressForm.address"
                      :placeholder="$t('administration.clients.create.street_label')"
                    ></b-form-input>
                  </div>
                  <div v-if="!showForm" class="section-container">
                    <span v-if="thirdParty.address">
                      {{ thirdParty.address }}
                    </span>
                    <p v-if="!thirdParty.address" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card-body pb-0">
                  <label for="">{{ $t('administration.clients.create.postcode_label') }}</label>
                  <div class="mw-500" v-if="showForm">
                    <b-form-input
                      qid="create-client-form-postcode"
                      type="text"
                      v-model="addressForm.postal_code"
                      :placeholder="$t('administration.clients.create.postcode_label')"
                    ></b-form-input>
                  </div>
                  <div v-if="!showForm" class="section-container">
                    <span v-if="thirdParty.postal_code">
                      {{ thirdParty.postal_code }}
                    </span>
                    <p v-if="!thirdParty.postal_code" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card-body pb-0">
                  <label for="">{{ $t('administration.clients.create.city_label') }}</label>
                  <div class="mw-500" v-if="showForm">
                    <b-form-input
                      qid="create-clients-form-city"
                      type="text"
                      v-model="addressForm.city"
                      :placeholder="$t('administration.clients.create.city_label')"
                    ></b-form-input>
                  </div>
                  <div v-if="!showForm" class="section-container">
                    <span v-if="thirdParty.city">
                      {{ thirdParty.city }}
                    </span>
                    <p v-if="!thirdParty.city" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card-body pb-0">
                  <label for="">{{ $t('administration.clients.create.state_label') }}</label>
                  <div class="mw-500" v-if="showForm">
                    <b-form-input
                      qid="create-clients-form-state"
                      type="text"
                      v-model="addressForm.state"
                      :placeholder="$t('administration.clients.create.state_label')"
                    ></b-form-input>
                  </div>
                  <div v-if="!showForm" class="section-container">
                    <span v-if="thirdParty.state">
                      {{ thirdParty.state }}
                    </span>
                    <p v-if="!thirdParty.state" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card-body pb-0">
                  <label for="">{{ $t('administration.clients.create.country_label') }}</label>
                  <div class="mw-500" v-if="showForm">
                    <multiselect
                        v-model="selectedCountry"
                        :options="countries"
                        :multiple="false"
                        track-by="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        :placeholder="$t('administration.clients.create.country_label')"
                        label="name"
                        qid="create-tp-countries-select"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <img v-bind:src="props.option.flag" height="13" class="align-baseline" />
                        <span class="mr-2">
                          {{ props.option.name }}
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <img v-bind:src="props.option.flag" height="13" class="align-baseline" />
                        <span class="mr-2">
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div v-if="!showForm" class="section-container">
                    <span v-if="thirdParty.country">
                      <img v-bind:src="thirdParty.country_with_flag.flag" height="13" class="align-baseline mr-1" v-if="thirdParty.country_with_flag"/>
                      {{ thirdParty.country }}
                    </span>
                    <p v-if="!thirdParty.country" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card-body">
                  <label for="">{{ $t('administration.clients.create.cvr_label') }}</label>
                  <div class="mw-500" v-if="showForm">
                    <b-form-input
                      qid="create-client-form-cvr"
                      type="text"
                      v-model="addressForm.company_number"
                      :placeholder="$t('administration.clients.create.cvr_label')"
                    ></b-form-input>
                    <div class="text-right">
                      <b-button class="mt-3"
                                variant="secondary"
                                @click="hideFormFunction"
                                qid="integrity-edit-cancel-button"
                      >
                        {{ $t('activities.integrity_check.cancel') }}
                      </b-button>
                      <b-button class="mt-3 ml-3"
                                variant="success"
                                @click="addressFormSubmit"
                                qid="integrity-edit-submit-button"
                      >
                        {{ $t('activities.integrity_check.submit') }}
                      </b-button>
                    </div>
                  </div>
                  <div v-if="!showForm" class="section-container">
                    <span v-if="thirdParty.company_number">
                      {{ thirdParty.company_number }}
                    </span>
                    <p v-if="!thirdParty.company_number" class="text-muted">
                      {{ $t('system.missing_input') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </b-tab>

        <!-- Contracts tab -->
        <b-tab :title="$t('maintenance.third_parties.vendors.tab.contracts')" class="mt-3" @click="resetHistoryTab">
          <div class="card pagination-margin"
               qid="data-objects-section"
          >
            <div class="card-body">
              <h5 class="mb-2" qid="do-table-title">
                {{ $t('maintenance.third_parties.vendors.tab.contracts') }}
              </h5>

              <table class="table table-responsive-md table-list-secondary">
                <thead>
                  <tr>
                    <th scope="col"
                        class="cursor-pointer"
                        @click="selectSort('name')"
                        qid="sort-tp-name"
                    >
                      {{ $t('maintenance.third_parties.table.name') }}
                      <span class="ml-2">
                        <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                        <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                        <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                      </span>
                    </th>
                    <th scope="col">
                      {{ $t('processes.table.owners') }}
                    </th>
                    <th scope="col">
                      {{ $t('maintenance.third_parties.table.vendors') }}
                    </th>
                    <th scope="col">
                      {{ $t('processes.table.tags') }}
                    </th>
                    <th scope="col"
                        class="cursor-pointer"
                        @click="selectSort('type')"
                        qid="sort-tp-type"
                    >
                      {{ $t('table.category') }}
                      <span class="ml-2">
                        <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'type'"/>
                        <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'type'"/>
                        <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'type'"/>
                      </span>
                    </th>
                    <th scope="col"
                        class="cursor-pointer"
                        @click="selectSort('updated_at')"
                        qid="sort-p-name"
                    >
                      {{ $t('system.last_updated') }}
                      <span class="ml-2">
                        <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                        <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                        <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="object in tableItems">
                    <td>
                      <router-link
                          :to="{ name: 'contracts.view', params: { slug: object.slug }}"
                          qid="tp-view-page-link"
                      >
                        {{object.name}}
                      </router-link>
                    </td>
                    <td>
                      <span v-if="object.users && object.users.length">
                        <span class="mr-1" v-for="user in getReducedUsers(object.users)">
                          <span qid="profile-photo">
                            <Avatar :object="user" size="xs"></Avatar>
                          </span>
                        </span>
                        <span v-if="object.users.length > 3"
                              class="badge cursor-pointer"
                              @click="openUsersModal(object)"
                        >
                          <font-awesome-icon icon="users"/>
                          + {{ object.users.length - 3 }}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span class="mr-1 text-normal" v-for="item in getReducedVendors(object.vendors)">
                        <span qid="profile-photo">
                          <Avatar :object="item" size="xs"></Avatar>
                          {{ item.name }}
                        </span>
                      </span>
                      <span v-if="object.vendors.length > 3"
                            class="badge cursor-pointer"
                            @click="openVendorsModal(object)"
                      >
                      + {{ object.vendors.length - 3 }}
                      </span>
                    </td>
                    <td>
                      <span class="mr-1" v-for="tag in getReducedTags(object.tags_translated)" qid="p-tags-list">
                        <span class="badge mb-1" :title="tag.name_translated">
                          <font-awesome-icon icon="tag"/>
                          {{ tag.name_translated | truncate(35) }}
                        </span>
                      </span>
                      <span v-if="object.tags_translated.length > 3"
                            class="badge cursor-pointer"
                            @click="openTagsModal(object)"
                      >
                        <font-awesome-icon icon="tags"/>
                        + {{ object.tags_translated.length - 3 }}
                      </span>
                    </td>
                    <td>
                      <span class="badge" v-if="object.type" :title="object.type">
                        {{ object.type | truncate(35) }}
                      </span>
                    </td>
                    <td>
                      <span class="text-normal">
                        {{ object.updated_at | formatDate }}
                      </span>
                    </td>
                  </tr>
                  <tr v-if="!loading && !tableItems.length" qid="no-results-row" class="text-center">
                    <td colspan="10">
                      <h5 class="mt-3">
                        {{ $t('system.no_results') }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="card-body">
              <div class="pagination row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="float-left">
                      <span class="text-muted pagination-counter">
                        {{ $t('pagination.entries_per_page') }}:
                      </span>
                    </div>
                    <div class="float-left">
                      <multiselect
                          v-model="perPage"
                          :options="perPageOptions"
                          track-by="value"
                          label="value"
                          :select-label="$t('system.dropdown_select_label')"
                          :selected-label="$t('system.dropdown_selected_label')"
                          :deselect-label="$t('system.dropdown_deselect_label')"
                          qid="create-org-form-organization-level"
                          @select="perPageChanged"
                          :placeholder="selectedPerPage"
                          @remove="perPageRemoved"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <b-pagination
                      class="float-right"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="selectedPerPage"
                      aria-controls="my-table"
                      @change="pageChanged"
                      limit="1"
                  >
                    <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
                    <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
                    <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
                    <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
                    <div slot="ellipsis-text">
                      <b-spinner small type="grow"></b-spinner>
                      <b-spinner small type="grow"></b-spinner>
                      <b-spinner small type="grow"></b-spinner>
                    </div>
                    <span class="text-muted" slot="page" slot-scope="{ page, active }">
                      <span v-if="active">{{getPerPage}}</span>
                    </span>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>

        </b-tab>

        <!-- Subdata processors on contracts tab -->
        <b-tab :title="$t('maintenance.third_parties.vendors.tab.subdata_processor_contracts')" class="mt-3" @click="resetHistoryTab" v-if="getClient.gdprAdvancedModule">
          <div class="card pagination-margin"
               qid="data-objects-section"
          >
            <div class="card-body">
              <h5 class="mb-2" qid="do-table-title">
                {{ $t('maintenance.third_parties.vendors.tab.subdata_processor') }}
              </h5>

              <table class="table table-responsive-md table-list-secondary">
                <thead>
                <tr>
                  <th scope="col"
                      class="cursor-pointer"
                      @click="selectProcessorSort('name')"
                      qid="sort-tp-name"
                  >
                    {{ $t('maintenance.third_parties.table.name') }}
                    <span class="ml-2">
                      <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                      <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                      <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                    </span>
                  </th>
                  <th scope="col">
                    {{ $t('processes.table.owners') }}
                  </th>
                  <th scope="col">
                    {{ $t('maintenance.third_parties.table.vendors') }}
                  </th>
                  <th scope="col">
                    {{ $t('processes.table.tags') }}
                  </th>
                  <th scope="col"
                      class="cursor-pointer"
                      @click="selectProcessorSort('type')"
                      qid="sort-tp-type"
                  >
                    {{ $t('table.category') }}
                    <span class="ml-2">
                      <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'type'"/>
                      <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'type'"/>
                      <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'type'"/>
                    </span>
                  </th>
                  <th scope="col"
                      class="cursor-pointer"
                      @click="selectProcessorSort('updated_at')"
                      qid="sort-p-name"
                  >
                    {{ $t('system.last_updated') }}
                    <span class="ml-2">
                      <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                      <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                      <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="object in tableProcessorContractsItems">
                  <td>
                    <router-link
                        :to="{ name: 'contracts.view', params: { slug: object.third_party.slug }}"
                        qid="tp-view-page-link"
                    >
                      {{object.third_party.name}}
                    </router-link>
                  </td>
                  <td>
                    <span v-if="object.third_party.users && object.third_party.users.length">
                      <span class="mr-1" v-for="user in getReducedUsers(object.third_party.users)">
                        <span qid="profile-photo">
                          <Avatar :object="user" size="xs"></Avatar>
                        </span>
                      </span>
                      <span v-if="object.third_party.users.length > 3"
                            class="badge cursor-pointer"
                            @click="openUsersModal(object.third_party)"
                      >
                        <font-awesome-icon icon="users"/>
                        + {{ object.third_party.users.length - 3 }}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span class="mr-1 text-normal" v-for="item in getReducedVendors(object.third_party.vendors)">
                      <span qid="profile-photo">
                        <Avatar :object="item" size="xs"></Avatar>
                        {{ item.name }}
                      </span>
                    </span>
                    <span v-if="object.third_party.vendors.length > 3"
                          class="badge cursor-pointer"
                          @click="openVendorsModal(object.third_party)"
                    >
                      + {{ object.third_party.vendors.length - 3 }}
                    </span>
                  </td>
                  <td>
                    <span class="mr-1" v-for="tag in getReducedTags(object.third_party.tags_translated)" qid="p-tags-list">
                      <span class="badge mb-1" :title="tag.name_translated">
                        <font-awesome-icon icon="tag"/>
                        {{ tag.name_translated | truncate(35) }}
                      </span>
                    </span>
                    <span v-if="object.third_party.tags_translated.length > 3"
                          class="badge cursor-pointer"
                          @click="openTagsModal(object.third_party)"
                    >
                      <font-awesome-icon icon="tags"/>
                      + {{ object.third_party.tags_translated.length - 3 }}
                    </span>
                  </td>
                  <td>
                    <span class="badge" v-if="object.third_party.type" :title="object.third_party.type">
                      {{ object.third_party.type | truncate(35) }}
                    </span>
                  </td>
                  <td>
                    <span class="text-normal">
                      {{ object.third_party.updated_at | formatDate }}
                    </span>
                  </td>
                </tr>
                <tr v-if="!loading && !tableProcessorContractsItems.length" qid="no-results-row" class="text-center">
                  <td colspan="10">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="card-body">
              <div class="pagination row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="float-left">
                      <span class="text-muted pagination-counter">
                        {{ $t('pagination.entries_per_page') }}:
                      </span>
                    </div>
                    <div class="float-left">
                      <multiselect
                          v-model="perPage"
                          :options="perPageOptions"
                          track-by="value"
                          label="value"
                          :select-label="$t('system.dropdown_select_label')"
                          :selected-label="$t('system.dropdown_selected_label')"
                          :deselect-label="$t('system.dropdown_deselect_label')"
                          qid="create-org-form-organization-level"
                          @select="processorPerPageChanged"
                          :placeholder="selectedPerPage"
                          @remove="processorPerPageRemoved"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <b-pagination
                      class="float-right"
                      v-model="currentPage"
                      :total-rows="processorRows"
                      :per-page="selectedPerPage"
                      aria-controls="my-table"
                      @change="processorPageChanged"
                      limit="1"
                  >
                    <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
                    <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
                    <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
                    <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
                    <div slot="ellipsis-text">
                      <b-spinner small type="grow"></b-spinner>
                      <b-spinner small type="grow"></b-spinner>
                      <b-spinner small type="grow"></b-spinner>
                    </div>
                    <span class="text-muted" slot="page" slot-scope="{ page, active }">
                      <span v-if="active">{{processorGetPerPage}}</span>
                    </span>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>

        </b-tab>


        <!-- Subdata processors tab -->
        <b-tab :title="$t('maintenance.third_parties.vendors.tab.subdata_processor')" class="mt-3" @click="resetHistoryTab" v-if="getClient.gdprAdvancedModule">
          <div class="card pagination-margin"
               qid="data-objects-section"
          >
            <div class="card-body">
              <h5 class="mb-2" qid="do-table-title">
                {{ $t('maintenance.third_parties.vendors.tab.subdata_processor') }}
              </h5>

              <table class="table table-responsive-md table-list-secondary">
                <thead>
                <tr>
                  <th scope="col"
                      class="cursor-pointer"
                      @click="selectProcessorSort('name')"
                      qid="sort-tp-name"
                  >
                    {{ $t('maintenance.third_parties.table.name') }}
                    <span class="ml-2">
                      <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                      <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                      <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                    </span>
                  </th>
                  <th scope="col">
                    {{ $t('processes.table.owners') }}
                  </th>
                  <th scope="col">
                    {{ $t('systems.third_parties.create.tp_label') }}
                  </th>
                  <th scope="col">
                    {{ $t('processes.table.tags') }}
                  </th>
                  <th scope="col"
                      class="cursor-pointer"
                      @click="selectProcessorSort('type')"
                      qid="sort-tp-type"
                  >
                    {{ $t('table.category') }}
                    <span class="ml-2">
                      <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'type'"/>
                      <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'type'"/>
                      <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'type'"/>
                    </span>
                  </th>
                  <th scope="col"
                      class="cursor-pointer"
                      @click="selectProcessorSort('updated_at')"
                      qid="sort-p-name"
                  >
                    {{ $t('system.last_updated') }}
                    <span class="ml-2">
                      <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                      <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                      <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="object in tableProcessorItems">
                  <td>
                    <router-link
                        :to="{ name: 'contracts.third_parties.view', params: { slug: object.vendor.slug }}"
                        qid="tp-view-page-link"
                    >
                      <Avatar :object="object.vendor" size="xs"></Avatar>
                      {{object.vendor.name}}
                    </router-link>
                  </td>
                  <td>
                    <span v-if="object.third_party.users && object.third_party.users.length">
                      <span class="mr-1" v-for="user in getReducedUsers(object.third_party.users)">
                        <span qid="profile-photo">
                          <Avatar :object="user" size="xs"></Avatar>
                        </span>
                      </span>
                      <span v-if="object.third_party.users.length > 3"
                            class="badge cursor-pointer"
                            @click="openUsersModal(object.third_party)"
                      >
                        <font-awesome-icon icon="users"/>
                        + {{ object.third_party.users.length - 3 }}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span class="mr-1 text-normal">
                      <span qid="profile-photo">
                        {{ object.third_party.name }}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span class="mr-1" v-for="tag in getReducedTags(object.third_party.tags_translated)" qid="p-tags-list">
                      <span class="badge mb-1" :title="tag.name_translated">
                        <font-awesome-icon icon="tag"/>
                        {{ tag.name_translated | truncate(35) }}
                      </span>
                    </span>
                    <span v-if="object.third_party.tags_translated.length > 3"
                          class="badge cursor-pointer"
                          @click="openTagsModal(object.third_party)"
                    >
                      <font-awesome-icon icon="tags"/>
                      + {{ object.third_party.tags_translated.length - 3 }}
                    </span>
                  </td>
                  <td>
                    <span class="badge" v-if="object.third_party.type" :title="object.third_party.type">
                      {{ object.third_party.type | truncate(35) }}
                    </span>
                  </td>
                  <td>
                    <span class="text-normal">
                      {{ object.third_party.updated_at | formatDate }}
                    </span>
                  </td>
                </tr>
                <tr v-if="!vendorsLoading && !tableProcessorItems.length" qid="no-results-row" class="text-center">
                  <td colspan="10">
                    <h5 class="mt-3">
                      {{ $t('system.no_results') }}
                    </h5>
                  </td>
                </tr>
                <tr v-if="vendorsLoading" qid="no-results-row-rr" class="text-center">
                  <td colspan="10">
                    <div class="mt-2 mb-4">
                      <div class="swing-container">
                        <div class="swing">
                          <div class="swing-l"></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div class="swing-r"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="card-body">
              <div class="pagination row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="float-left">
                      <span class="text-muted pagination-counter">
                        {{ $t('pagination.entries_per_page') }}:
                      </span>
                    </div>
                    <div class="float-left">
                      <multiselect
                          v-model="perPage"
                          :options="perPageOptions"
                          track-by="value"
                          label="value"
                          :select-label="$t('system.dropdown_select_label')"
                          :selected-label="$t('system.dropdown_selected_label')"
                          :deselect-label="$t('system.dropdown_deselect_label')"
                          qid="create-org-form-organization-level"
                          @select="processorPerPageChanged"
                          :placeholder="selectedPerPage"
                          @remove="processorPerPageRemoved"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <b-pagination
                      class="float-right"
                      v-model="currentPage"
                      :total-rows="processorRows"
                      :per-page="selectedPerPage"
                      aria-controls="my-table"
                      @change="processorPageChanged"
                      limit="1"
                  >
                    <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
                    <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
                    <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
                    <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
                    <div slot="ellipsis-text">
                      <b-spinner small type="grow"></b-spinner>
                      <b-spinner small type="grow"></b-spinner>
                      <b-spinner small type="grow"></b-spinner>
                    </div>
                    <span class="text-muted" slot="page" slot-scope="{ page, active }">
                      <span v-if="active">{{processorGetPerPage}}</span>
                    </span>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>

        </b-tab>

        <!-- Third party history tab -->
        <b-tab :title="$t('maintenance.third_parties.tab.history')" class="mt-3" @click="historyTabClicked">
          <div v-if="showHistoryTab">
            <VendorHistory :object="thirdParty"></VendorHistory>
          </div>
        </b-tab>

      </b-tabs>


      <!-- Edit third party modal template -->
      <b-modal ref="edit-third-party" hide-footer>
        <div class="d-block text-center">
          <h4 qid="update-tp-title">
            {{ $t('maintenance.third_parties.vendors.update.title') }}
          </h4>
        </div>
        <b-alert v-if="error.length"
                 show
                 variant="danger"
                 qid="edit-tp-error"
        >
          {{ error }}
        </b-alert>
        <b-form @submit="editTPFormSubmit" qid="edit-tp-form">
          <div class="form-container">
            <b-form-group>
              <span class="align-middle mr-4">
                <img v-bind:src="getAvatar"
                     class="rounded-circle"
                     width="120"
                     height="120"
                     qid="profile-photo"
                >
              </span>
              <span class="align-middle">
                <label class="btn btn-secondary cursor-pointer pl-2 pr-2">
                  <input type="file"
                         name="fileupload"
                         value="fileupload"
                         id="file"
                         @change="onFileChange"
                  >
                  <span>
                    <font-awesome-icon icon="cloud-upload-alt" />
                    {{ $t('maintenance.third_parties.vendors.upload_picture') }}
                  </span>
                </label>
              </span>
            </b-form-group>
            <b-form-group>
              <label qid="edit-tp-name-label">
                {{ $t('maintenance.third_parties.create.name_label') }}
              </label>
              <span class="text-red">*</span>
              <b-form-input
                qid="edit-tp-form-name"
                type="text"
                v-model="tpForm.name"
                :maxLength="255"
                required
                :placeholder="$t('maintenance.third_parties.create.name_placeholder')"
              ></b-form-input>
            </b-form-group>

          </div>
          <div class="text-right">
            <b-button class="mt-3"
                      variant="secondary"
                      @click="hideEditTPModal"
                      qid="edit-tp-cancel-button"
            >
              {{ $t('maintenance.third_parties.create.cancel') }}
            </b-button>
            <b-button type="submit"
                      class="mt-3 ml-3"
                      variant="success"
                      :disabled="buttonDisabled"
                      qid="edit-tp-submit-button"
                      v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
            >
              <span v-if="buttonDisabled" class="mr-1">
                <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
              </span>
              {{ $t('maintenance.third_parties.create.submit') }}
            </b-button>
          </div>
        </b-form>
      </b-modal>


      <!-- Delete third party confirm modal template -->
      <b-modal ref="delete-third-party" hide-footer>
        <div class="d-block text-center">
          <h4 qid="delete-tp-title">
            {{ $t('maintenance.third_parties.delete.title') }}
          </h4>
        </div>
        <h5 class="text-center">
          {{ $t('maintenance.third_parties.delete.description') }} {{ itemToDelete.name }}
        </h5>
        <h5 class="text-center">
          {{ $t('maintenance.third_parties.delete.are_you_sure') }}
        </h5>

        <h6 class="mt-2" v-if="tableItems && tableItems.length && itemToDelete && itemToDelete.id">
          {{ $t('maintenance.third_parties.delete.contracts_notice') }}
        </h6>
        <table class="table table-responsive-md" v-if="tableItems && tableItems.length && itemToDelete && itemToDelete.id">
          <thead>
          <tr>
            <th>
              {{ $t('maintenance.third_parties.table.name') }}
            </th>
            <th>
              {{ $t('system.last_updated') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="object in tableItems" v-if="tableItems && tableItems.length">
            <td>
              <router-link
                  :to="{ name: 'contracts.view', params: { slug: object.slug }}"
                  qid="tp-view-page-link"
              >
                {{object.name}}
              </router-link>
            </td>
            <td>
              <span>
                {{ object.updated_at | formatDate }}
              </span>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="text-center">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideConfirmDeleteModal"
                    qid="delete-tp-cancel-button"
          >
            {{ $t('maintenance.third_parties.delete.cancel') }}
          </b-button>
          <b-button @click="confirmDeleteTP(itemToDelete)"
                    class="mt-3 ml-3"
                    variant="success"
                    qid="delete-tp-submit-button"
                    v-if="isGranted([permissions.THIRD_PARTY_DELETE, permissions.THIRD_PARTY_DELETE_OWN], itemToDelete) && (!getClient.parent || (getClient.parent && itemToDelete.partition)) && !tableItems.length"
          >
            {{ $t('maintenance.third_parties.delete.submit') }}
          </b-button>
        </div>
      </b-modal>


      <!-- Crop image modal -->
      <b-modal ref="crop-image" hide-footer size="lg">
        <div class="d-block text-center">
          <h4 qid="edit-profile-modal-title">
            {{ $t('Crop image') }}
          </h4>
        </div>
        <div class="form-container" style="max-height: 600px;">
          <img v-bind:src="imageToCrop"
               qid="profile-photo"
               id="crop"
          >
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideCropModal"
                    qid="edit-profile-cancel-button"
          >
            {{ $t('profile.password.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    id="crop-button"
                    :disabled="buttonDisabled"
                    qid="edit-profile-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('profile.password.submit') }}
          </b-button>
        </div>
      </b-modal>


      <ConfirmRemoveAttachment
          :object="selectedFile"
          :show="showDeleteAttachmentModal"
          type="file"
          @closeConfirmModal="closeDeleteAttachmentModal"
      ></ConfirmRemoveAttachment>

      <ConfirmRemoveAttachment
          :object="selectedLink"
          :show="showDeleteLinkModal"
          type="link"
          @closeConfirmModal="closeDeleteLinkModal"
      ></ConfirmRemoveAttachment>

      <ShowMoreModal
          :items="selectedItem.tags_translated"
          :show="showTagsModal"
          @closeViewModal="closeTagsModal"
          type="tags"
      ></ShowMoreModal>

      <ShowMoreModal
          :items="selectedItem.users"
          :show="showUsersModal"
          @closeViewModal="closeUsersModal"
          type="users"
      ></ShowMoreModal>

      <ShowMoreModal
          :items="selectedItem.vendors"
          :show="showVendorsModal"
          @closeViewModal="closeVendorsModal"
          type="vendors"
      ></ShowMoreModal>
    </div>


    <div v-if="show404">
      <page404></page404>
    </div>

    <div v-if="show403">
      <page403></page403>
    </div>

  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import Datepicker from 'vuejs-datepicker';
import Avatar from '@/components/Avatar.vue';
import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
import VendorHistory from '@/views/ThirdParty/VendorHistory.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import GuideButton from '@/components/guide/GuideButton.vue';
import page404 from '@/components/404block.vue';
import page403 from '@/components/403block.vue';

import VueCropper from 'vue-cropperjs';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'VendorView',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('maintenance.third_parties.vendors.title'),
          to: '/third-parties'
        }
      ],
      thirdParty: {},
      showInput: false,
      showSingleLinkLabel: true,
      descriptionLength: config.descriptionLength,
      edit: this.$route.params.edit,
      inputLink: '',
      showFullDescription: false,
      showFullContact: false,
      itemToDelete: {},
      tpForm: {
        name: '',
        description: '',
        file: {}
      },
      addressForm: {
        company_number: '',
        address: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
      },
      showForm: false,
      error: '',
      types: [],
      typeInput: '',
      buttonDisabled: false,
      showDescriptionInput: false,
      processEditDescription: '',
      showContactInput: false,
      processEditContact: '',
      selectedFile: {},
      selectedLink: {},
      showDeleteAttachmentModal: false,
      showDeleteLinkModal: false,
      tabIndex: 0,
      myGuides: [],
      selectedTags: [],
      tags: [],
      users: [],
      newTags: [],
      tableItems: [],
      tableProcessorItems: [],
      tableProcessorContractsItems: [],
      loading: false,
      selectedDO: [],
      selectedUsers: [],
      selectedDataObject: {},
      viewDataObjectActivitiesModal: false,
      selectedDataObjectActivities: [],
      showHistoryTab: false,
      showRiskTab: false,
      showDataObjectDescription: false,
      // Pagination
      perPageOptions: [
        { name: '10', value: '10' },
        { name: '20', value: '20' },
        { name: '50', value: '50' },
        { name: '100', value: '100' }
      ],
      perPage: '',
      selectedPerPage: "10",
      currentPage: 1,
      totalRows: 0,
      totalProcessorRows: 0,
      keywordValue: '',
      selectedSort: '',
      selectedDirection: '',
      myTasks: [],
      selectedItem: {},
      showTagsModal: false,
      showUsersModal: false,
      viewDataModal: false,
      showVendorsModal: false,
      showAssessmentsTab: false,
      imageToCrop: '',
      avatar: '',
      avatarLarge: '',
      croppedBlob: {},
      countries: [],
      selectedCountry: [],
      show404: false,
      show403: false,
      vendorsLoading: true
    }
  },
  components: {
    Datepicker,
    Avatar,
    ConfirmRemoveAttachment,
    VendorHistory,
    ShowMoreModal,
    GuideButton,
    page404,
    page403
  },
  computed: {
    rows() {
      // Use computed value for rows because of dynamic update
      return this.totalRows
    },
    processorRows() {
      // Use computed value for rows because of dynamic update
      return this.totalProcessorRows
    },
    getClient() {
      return this.$store.state.client
    },
    getAvatar(){
      if (this.avatarLarge) {
        return this.avatarLarge;
      } else {
        return require('@/assets/tp-logo.png');
      }
    },
    getTabIndex() {
      return this.$store.state.tabIndex
    },
    getStorePerPage() {
      return this.$store.state.perPage
    },
    getPerPage() {
      let page = this.currentPage;
      let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
      let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
      let selectedOf = this.totalRows;

      if(selectedTo > selectedOf){
        selectedTo = selectedOf
      }

      return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
    },
    processorGetPerPage() {
      let page = this.currentPage;
      let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
      let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
      let selectedOf = this.totalProcessorRows;

      if(selectedTo > selectedOf){
        selectedTo = selectedOf
      }

      return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
    },
    getReloadContent() {
      return this.$store.state.updateContent
    },
    getReloadPage() {
      return this.$store.state.updatePage
    }
  },
  watch: {
    getClient: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        let self = this;
        setTimeout(function(){
          if (self.$route.name == 'contracts.third_parties.view') {
            self.goToList()
          }
        }, 800);
      } else {
        this.loadContent()
      }
    },
    getTabIndex: function (newValue, oldValue) {
      this.handleUpdateTab(newValue)
    }
  },
  methods: {
    debounceInput: _.debounce(function() {
      this.loadContracts()
    }, 400),
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    getReducedUsers(users) {
      let reducedUsers = []
      if (users) {
        if (users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
      }
      return reducedUsers;
    },
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    openUsersModal(object) {
      this.selectedItem = object
      this.showUsersModal = true
    },
    closeUsersModal() {
      this.selectedItem = {}
      this.showUsersModal = false
    },
    getReducedVendors(vendors) {
      let reducedVendors = []
      if (vendors) {
        if (vendors.length > 3) {
          reducedVendors = _.cloneDeep(vendors);
          reducedVendors = reducedVendors.slice(0, 3);
        } else {
          reducedVendors = vendors;
        }
      }
      return reducedVendors;
    },
    openVendorsModal(object) {
      this.selectedItem = object
      this.showVendorsModal = true
    },
    closeVendorsModal() {
      this.selectedItem = {}
      this.showVendorsModal = false
    },
    showFormFunction() {
      let self = this;

      this.addressForm.company_number = this.thirdParty.company_number;
      this.addressForm.address = this.thirdParty.address;
      this.addressForm.city = this.thirdParty.city;
      this.addressForm.state = this.thirdParty.state;
      this.addressForm.postal_code = this.thirdParty.postal_code;

      self.selectedCountry = [];
      if (this.thirdParty.country) {
        _.find(this.countries, function(item) {
          if (item.name === self.thirdParty.country) {
            self.selectedCountry = item
          }
        });
      }

      this.addressForm.error = '';
      this.showForm = true;
    },
    hideFormFunction() {
      this.showForm = false;
    },
    addressFormSubmit() {
      this.buttonDisabled = true;
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;

      if (this.selectedCountry && this.selectedCountry.name) {
        this.addressForm.country = this.selectedCountry.name
      }

      piincHttp.put('vendors/' + this.thirdParty.slug, this.addressForm).then(function(response) {
        let client = response.data;
        self.buttonDisabled = false;
        self.hideFormFunction();
        self.breadcrumbItems.pop();

        self.loadContent();
      }, function(error) {
        if (error.status !== 200) {
          self.buttonDisabled = false;
          self.addressForm.error = error.data.message;
        }
      })
    },
    showCropModal() {
      let self = this

      this.$refs['crop-image'].show()
      this.buttonDisabled = false;

      setTimeout(function(){
        const image = document.getElementById('crop');
        const cropper = new Cropper(image, {
          aspectRatio: 1,
          viewMode: 2,
          crop(event) {},
        });

        document.getElementById('crop-button').addEventListener('click', function(){
          cropper.getCroppedCanvas().toBlob(function (blob) {

            self.avatar = URL.createObjectURL(blob);
            self.avatarLarge = URL.createObjectURL(blob);
            self.imageToCrop = URL.createObjectURL(blob);
            self.croppedBlob = blob

            self.hideCropModal()

          });
        })
      }, 500);

    },
    hideCropModal() {
      this.$refs['crop-image'].hide()
    },
    onFileChange(file) {
      this.tpForm.file = file;
      const avatar = file.target.files[0];
      this.avatar = URL.createObjectURL(avatar);
      this.avatarLarge = URL.createObjectURL(avatar);
      this.imageToCrop = URL.createObjectURL(avatar);

      this.showCropModal()

    },
    selectSort(sort) {
      // If old and new sort are same, switch just direction
      if (sort === this.selectedSort) {
        this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
      } else {
        // If different sort, set sort and set direction to default
        this.selectedDirection = 'desc'
        this.selectedSort = sort;
      }
      this.loadContracts();
    },
    selectProcessorSort(sort) {
      // If old and new sort are same, switch just direction
      if (sort === this.selectedSort) {
        this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
      } else {
        // If different sort, set sort and set direction to default
        this.selectedDirection = 'desc'
        this.selectedSort = sort;
      }
      this.loadProcessors();
    },
    pageChanged(page) {
      this.loadContracts(page)
    },
    perPageChanged(perPage) {
      let self = this;
      // Load content when page changed listener is triggered
      this.loadContracts(1, perPage.value)
      self.selectedPerPage = perPage.value
      this.$store.commit('storePerPage', this.selectedPerPage);
    },
    perPageRemoved() {
      let self = this;
      // Load content when page changed listener is triggered
      self.selectedPerPage = self.perPageOptions[0].value
      this.loadContracts(1, self.selectedPerPage)
    },
    processorPageChanged(page) {
      this.loadProcessors(page)
    },
    processorPerPageChanged(perPage) {
      let self = this;
      // Load content when page changed listener is triggered
      this.loadProcessors(1, perPage.value)
      self.selectedPerPage = perPage.value
      this.$store.commit('storePerPage', this.selectedPerPage);
    },
    processorPerPageRemoved() {
      let self = this;
      // Load content when page changed listener is triggered
      self.selectedPerPage = self.perPageOptions[0].value
      this.loadProcessors(1, self.selectedPerPage)
    },
    goToList() {
      this.$router.push('/third-parties')
    },
    historyTabClicked() {
      this.showAssessmentsTab = false;
      this.showHistoryTab = true;
      this.showRiskTab = false;
    },
    resetHistoryTab() {
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    getTask(code) {
      let existingTask = _.find(this.myTasks, function(object) {
        return object.code == code
      });
      return existingTask
    },
    openToolbar(object) {
      this.$store.commit('storeTaskToolbarState', true);
      this.$store.commit('storeActiveTask', object);
    },
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'Vendor' && object.enabled
      });
      return existingGuide
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'Vendor',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    },
    showDescriptionInputFunction() {
      this.showDescriptionInput = true;
      this.processEditDescription = this.thirdParty.description;
    },
    formDescriptionSubmit() {
      let self = this;
      piincHttp.put('vendors/' + this.$route.params.slug, {
        description: this.processEditDescription
      }).then(function(response) {
        self.thirdParty = response.data;
        self.showDescriptionInput = false;
        self.$toastr('success', self.$t('maintenance.third_parties.vendors.update.success'))
      }, function(error) {
        if (error.status !== 200) {
          self.error = error.data.message;
        }
      })
    },
    cancelDescriptionForm() {
      this.showDescriptionInput = false;
      this.processEditDescription = this.thirdParty.description;
    },
    resetFile() {
      let imagefile = document.querySelector('#file');
      imagefile.value = '';
    },
    openDeleteAttachmentModal(object) {
      this.selectedFile = object
      this.showDeleteAttachmentModal = true
    },
    closeDeleteAttachmentModal () {
      this.selectedFile = {}
      this.showDeleteAttachmentModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    openDeleteLinkModal(object) {
      this.selectedLink = object
      this.showDeleteLinkModal = true
    },
    closeDeleteLinkModal () {
      this.selectedLink = {}
      this.showDeleteLinkModal = false;
      // Remove last item in breadcrumbs, because it will be added again on load content
      this.breadcrumbItems.pop();
      this.loadContent();
    },
    handleLink() {
      let self = this;
      let formData = new FormData();

      if (!this.inputLink.startsWith('http')) {
        this.inputLink = 'http://' + this.inputLink
      }

      formData.append("url", this.inputLink);
      formData.append("model_type", this.thirdParty.who_am_i);
      formData.append("model_id", this.thirdParty.id);

      piincHttp.post('links', formData).then(function() {
        // Remove last item in breadcrumbs, because it will be added again on load content
        self.breadcrumbItems.pop();
        self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
        self.loadContent()
        self.inputLink = ''
        self.showInput = false
      }, function() {})
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    getUserAvatar(user){
      if (user && user.avatar) {
        return user.avatar;
      } else {
        return require('@/assets/avatar.png');
      }
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-third-party'].show()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-third-party'].hide()
    },
    confirmDeleteTP(object) {
      let self = this;
      piincHttp.delete('vendors/' + object.slug).then(function() {
        self.hideConfirmDeleteModal();
        self.goToList();
        self.$store.commit('storeUpdateTasks', true);
        self.$store.commit('storeActiveTask', {});
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('maintenance.third_parties.vendors.delete.success'))
      }, function() {});
    },
    showEditTPModal(object) {
      let self = this;
      this.error = ''
      this.tpForm.slug = object.slug;
      this.tpForm.name = object.name;
      self.avatar = object.logo
      self.avatarLarge = object.logo
      this.$refs['edit-third-party'].show()
    },
    hideEditTPModal() {
      this.$refs['edit-third-party'].hide()
    },
    editTPFormSubmit(evt) {
      let sizeError = false;
      var moment = require('moment');
      // Keep the reference in a variable
      // to use it inside function called when promise returned
      let self = this;
      evt.preventDefault();

      var formData = new FormData();

      if (this.croppedBlob.size > 10485760) {
        sizeError = true;
      }

      formData.append("logo", this.croppedBlob);
      formData.append("name", this.tpForm.name);
      formData.append("_method", 'put');

      if (sizeError) {
        self.error = self.$t('system.file_size')
      } else {
        this.buttonDisabled = true;
        piincHttp.post('vendors/' + self.thirdParty.slug, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function(response) {
          self.hideEditTPModal();
          self.buttonDisabled = false;
          // If slug is not changed, just reload content
          if (response.data.slug === self.thirdParty.slug) {
            // Remove last item in breadcrumbs, because it will be added again on load content
            self.breadcrumbItems.pop();
            self.loadContent();
          } else {
            // If slug changed, reload state with new param
            self.$router.push({ name: 'contracts.third_parties.view', params: { slug: response.data.slug }})
          }
          self.$toastr('success', self.$t('maintenance.third_parties.vendors.update.success'))
        }, function(error) {
          self.buttonDisabled = false;
          self.tpForm.error = error.data.message;
          if (error.data.errors.avatar) {
            self.tpForm.error += ' ' + error.data.errors.avatar[0]
          }
          alert(self.tpForm.error)
        })
      }

    },
    // Table list
    loadContracts(page, perPage) {

      if (this.getStorePerPage) {
        this.selectedPerPage = this.getStorePerPage;
      }

      let pageNumber = page ? page : 1;
      let perPageNumber = perPage ? perPage : this.selectedPerPage;
      let self = this;

      piincHttp.get('third-parties', { params:
        {
          page: pageNumber,
          per_page: perPageNumber,
          sort: this.selectedSort !== '' ? this.selectedSort : undefined,
          order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
          vendor: this.thirdParty.id,
          keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.tableItems = response.data.items;
        self.loading = false;
        // Set number of total items
        self.totalRows = response.data.total_count;
      }, function() {});

    },
    // Table list
    loadProcessors(page, perPage) {

      this.vendorsLoading = true;

      if (this.getStorePerPage) {
        this.selectedPerPage = this.getStorePerPage;
      }

      let pageNumber = page ? page : 1;
      let perPageNumber = perPage ? perPage : this.selectedPerPage;
      let self = this;

      piincHttp.get('vendors/' + this.$route.params.slug + '/' + 'subdata-processors', { params:
        {
          page: pageNumber,
          per_page: perPageNumber,
          sort: this.selectedSort !== '' ? this.selectedSort : undefined,
          order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
          keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.tableProcessorItems = response.data.items;
        self.vendorsLoading = false;
        // Set number of total items
        self.totalProcessorRows = response.data.total_count;
      }, function() {});

    },
    // Table list
    loadProcessorsContracts(page, perPage) {

      if (this.getStorePerPage) {
        this.selectedPerPage = this.getStorePerPage;
      }

      let pageNumber = page ? page : 1;
      let perPageNumber = perPage ? perPage : this.selectedPerPage;
      let self = this;

      piincHttp.get('vendors/' + this.$route.params.slug + '/' + 'subdata-processors-contracts', { params:
                {
                  page: pageNumber,
                  per_page: perPageNumber,
                  sort: this.selectedSort !== '' ? this.selectedSort : undefined,
                  order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
                  keyword: this.keywordValue !== '' ? this.keywordValue : undefined,
                  client: this.getClient.slug
                }
      }).then(function(response) {
        self.tableProcessorContractsItems = response.data.items;
        self.loading = false;
        // Set number of total items
        self.totalProcessorContractsRows = response.data.total_count;
      }, function() {});

    },
    loadCountries() {
      let self = this;
      piincHttp.get('countries-with-flag').then(function(response) {
        self.countries = response.data
      }, function() {});
    },
    loadContent() {
      let self = this;
      piincHttp.get('vendors/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug,
        }
      }).then(function(response) {
        self.thirdParty = response.data;
        self.loadTasks('update_basic_info');
        self.loadContracts();
        self.loadProcessors();
        self.loadProcessorsContracts();
        // Push Third party name in breadcrumb array
        self.breadcrumbItems.push({text: self.thirdParty.name, active: true});
      }, function(error) {
        if (error.status == 404) {
          self.show404 = true
        }
        if (error.status == 403) {
          self.show403 = true
        }
      });
    },
    loadTasks(code) {
      let self = this;
      if (this.getClient.projectModule) {
        piincHttp.get('projects/me/tasks', { params:
          {
            per_page: 1,
            status: 'pending',
            model_type: this.thirdParty.who_am_i,
            model_id: this.thirdParty.id,
            code: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myTasks.push(response.data.items[0])
          }
        }, function() {});
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('storeTabIndex', '');
    next()
  },
  created () {
    let self = this;
    if (this.getClient && this.getClient.slug) {
      this.loadContent()
    }

    this.loadCountries()

    this.loadGuides('update_basic_info');
    this.loadGuides('update_description');

    if (this.edit) {
      setTimeout(function(){
        self.showEditTPModal(self.thirdParty)
      }, 500);
    }

  }
}
</script>
