<template>
  <div class="mt-5 mb-5 text-center">
    <div>
      <div class="d-block text-center mt-4">
        <h1 qid="forbidden-title">
          {{ $t('system.forbidden') }}
        </h1>
      </div>
      <br>
      <h3>
        403
      </h3>
      <h5 class="text-center">
        {{ $t('error.forbidden.description') }}
      </h5>
      <h5 class="text-center">
        {{ $t('error.not_found.description_2') }}
      </h5>
      <div class="text-center mt-3">
        <router-link
            to="/"
            class="btn btn-success mt-3"
            qid="homepage-button"
        >
          {{ $t('error.not_found.homepage_button') }}
        </router-link>
      </div>
    </div>

  </div>


</template>

<script>
  import {config} from '@/config.js'

  export default {
    name: '403block',
    props: {
      object: {},
    },
    data: function() {
      return {

      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    methods: {

    },
    created () {
    }
  }
</script>
